import React, { useContext, useState } from 'react';
import Logo from '../../assets/logo.svg';
import activedashboardIcon from '../../assets/menu/Iconly-Bold-Graph.svg';
import activewallet from '../../assets/activemenu/Iconly-Bold-Wallet.svg';
import wallet from '../../assets/menu/Iconly-Bold-Wallet.svg';
import order from '../../assets/menu/Iconly-Bold-Filter.svg';
import addFriends from '../../assets/menu/Iconly-Bold-Add User.svg';
import Profil from '../../assets/menu/Profile.svg';
import notification from '../../assets/menu/Iconly-Bold-Notification.svg';
import mobile from '../../assets/mobile.JPG';
import notification1 from '../../assets/menu/Notification.svg';
import setting from '../../assets/menu/Iconly-Bold-Setting.svg';
import dashboardIcon from '../../assets/activemenu/Iconly-Bold-Graph.svg';
import profile from '../../assets/profile/profile.png';
import notifi from '../../assets/profile/notifi.png';
import orderIcon from '../../assets/order/Iconly-Bold-Filter.svg';
import { Link, NavLink, useHistory, withRouter } from 'react-router-dom';
import invitefriendsActive from '../../assets/invitefriends/Iconly-Bold-Add User.svg';
import logout from '../../assets/trade/down-icon/Iconly-Bold-Arrow - Up Square.svg';
import acc from '../../assets/profile/acc.png';
import userReducer from '../../state/user/reducer';
import { UserContext } from '../../state/user/userContext';
import { slide as Menu } from 'react-burger-menu'
const MenuItem = ({ icon, iconActive, title, url, currentURL }) => {
    return (
        <NavLink to={url} exact>
            <li>
                <img src={currentURL === url ? iconActive : icon} alt={title} />
                <p>{title}</p>
            </li>
        </NavLink>
    );
};
const Navigation = () => {
    const history = useHistory();
    const currentURL = history.location.pathname;
    const items = [
        {
            title: 'داشبورد',
            icon: dashboardIcon,
            iconActive: activedashboardIcon,
            url: '/dashboard',
        },
        {
            title: 'کیف پول',
            icon: wallet,
            iconActive: activewallet,
            url: '/wallet',
        },
        {
            title: 'سفارشات',
            icon: order,
            iconActive: orderIcon,
            url: '/order',
        },
        {
            title: 'دعوت از دوستان',
            icon: addFriends,
            iconActive: invitefriendsActive,
            url: '/invite-friend',
        },
        {
            title: ' پشتیبانی / تیکت ',
            icon: notification1,
            iconActive: notification,
            url: '/ticket',
        },
    ];
    const context = useContext(UserContext)
    const profileName = context?.state?.user?.email;
    const [state, setState] = useState({ menuOpen: false });
    const handleStateChange = (state) => {
        setState({ menuOpen: state.isOpen })
    }
    const closeMenu = () => {
        setState({ menuOpen: false })
    }
    const toggleMenu = () => {
        setState(state => ({ menuOpen: !state.menuOpen }))
    }
    return (
        <>
            <header className="header" id="outer-container" >
                <div className="header-part" style={{ paddingRight: "35px" }}>
                    <NavLink to={'/dashboard'} exact>
                        <div className="header-logo">
                            <img src={Logo} alt="logo" />
                            <h3>بی بیت</h3>
                        </div>
                    </NavLink>
                    <nav className="header-nav">
                        <ul className="hidden-md-down">
                            {items.map((i, index) => (
                                <MenuItem
                                    icon={i.icon}
                                    iconActive={i.iconActive}
                                    title={i.title}
                                    url={i.url}
                                    currentURL={currentURL}
                                    key={'MenuItem-' + index}
                                />
                            ))}
                        </ul>
                    </nav>
                </div>
                <div className="header-part" style={{ direction: "ltr" }}>
                    <NavLink to={'/ticket'} exact style={{ marginRight: "10px" }}>
                        <div className="header-logo">
                            <img src={notifi} alt="logo" />
                        </div>
                    </NavLink>
                    <NavLink to={'/profile'} exact>
                        <div className="header-logo">
                            <img src={profile} alt="logo" />
                        </div>
                    </NavLink>

                </div>

                <div className="header-part">
                    <div className="header-options">
                        <Menu
                            isOpen={state.menuOpen}
                            pageWrapId={"page-wrap"}
                            outerContainerId={"outer-container"}
                            onStateChange={(state) => handleStateChange(state)}
                            reveal
                            right
                            styles={{
                                bmBurgerButton: {
                                    position: 'fixed',
                                    width: '25px',
                                    height: '20px',
                                    top: '15px',
                                },
                                bmBurgerBars: {
                                    background: '#ddd'
                                },
                                bmBurgerBarsHover: {
                                    background: '#fff'
                                },
                                bmCrossButton: {
                                    height: '24px',
                                    width: '24px'
                                },
                                bmCross: {
                                    background: '#fff'
                                },
                                bmMenuWrap: {
                                    position: 'fixed',
                                    height: '100%',
                                    width: "fit-content",
                                    top: '0px',
                                    left: "auto"

                                },
                                bmMenu: {
                                    background: '#373a47',
                                    padding: '2.5em 1.5em 0',
                                    fontSize: '1.15em'
                                },
                                bmMorphShape: {
                                    fill: '#ddd'
                                },
                                bmItemList: {
                                    color: '#b8b7ad',
                                    padding: '0.8em'
                                },
                                bmItem: {
                                    display: 'block',
                                    marginBottom: "10px"
                                },
                                bmOverlay: {
                                    width: "fit-content",
                                    background: 'rgba(0, 0, 0, 0.3)'
                                }
                            }} >


                            <NavLink onClick={() => closeMenu()} to="/profile" exact activeClassName="header-options-item-active">
                                <div className="header-options-profile" title="پروفایل">
                                    <div className="header-options-icon header-options-icon-avatar">
                                        <img alt="user" src={Profil} />
                                    </div>
                                    <div style={{ color: "white", fontSize: "7pt", textAlign: "right" }}>
                                        <div style={{ fontSize: "10pt" }}>
                                            پروفایل
                                            <span style={{ color: "#ffda52" }}> (احراز هویت)</span>
                                        </div>
                                        <div>
                                            {profileName}
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                            <NavLink onClick={() => closeMenu()} to="/profile" exact activeClassName="header-options-item-active" title="حسابداری">
                                <div className="header-options-profile" title="حسابداری">
                                    <div className="header-options-icon header-options-icon-setting">
                                        <img alt="setting" src={acc} style={{width: "35px"}} />
                                    </div>
                                    <span style={{ color: "white", fontSize: "10pt" }}>  حسابداری</span>
                                </div>
                            </NavLink>
                            
                            <NavLink onClick={() => closeMenu()} to="/Echarge" exact>
                                <div className="header-options-profile" title="پروفایل">
                                    <div className="header-options-icon header-options-icon-notification" title=" خرید شارژ موبایل">
                                        <img alt="notiication" src={mobile} />
                                    </div>
                                    <span style={{ color: "white", fontSize: "10pt" }}>  خرید شارژ موبایل</span>
                                </div>
                            </NavLink>
                            
                            <NavLink onClick={() => closeMenu()} to="/notification" exact>
                                <div className="header-options-profile" title="پروفایل">
                                    <div className="header-options-icon header-options-icon-notification" title="پیام ها">
                                        <img alt="notiication" src={notification} />
                                    </div>
                                    <span style={{ color: "white", fontSize: "10pt" }}> پیامهای سیستم</span>
                                </div>
                            </NavLink>
                            <NavLink onClick={() => closeMenu()} to="/invite-friend" exact activeClassName="header-options-item-active" title="خروج">
                                <div className="header-options-profile" title="پروفایل">
                                    <div className="header-options-icon header-options-icon-setting">
                                        <img alt="setting" src={invitefriendsActive} />
                                    </div>
                                    <span style={{ color: "white", fontSize: "10pt" }}>   دعوت از دوستان </span>
                                </div>
                            </NavLink>
                            <NavLink onClick={() => closeMenu()} to="/order" exact activeClassName="header-options-item-active" title="خروج">
                                <div className="header-options-profile" title="پروفایل">
                                    <div className="header-options-icon header-options-icon-setting">
                                        <img alt="setting" src={orderIcon} />
                                    </div>
                                    <span style={{ color: "white", fontSize: "10pt" }}>تاریخچه سفارشات </span>
                                </div>
                            </NavLink>
                            <NavLink onClick={() => closeMenu()} to="/ticket" exact activeClassName="header-options-item-active" title="پشتیبانی">
                                <div className="header-options-profile" title="پشتیبانی">
                                    <div className="header-options-icon header-options-icon-setting">
                                        <img alt="setting" src={notification1} />
                                    </div>
                                    <span style={{ color: "white", fontSize: "10pt" }}>  پشتیبانی</span>
                                </div>
                            </NavLink>
                            <NavLink onClick={() => closeMenu()} to="/setting" exact activeClassName="header-options-item-active" title="تنظیمات">
                                <div className="header-options-profile" title="پروفایل">
                                    <div className="header-options-icon header-options-icon-setting">
                                        <img alt="setting" src={setting} />
                                    </div>
                                    <span style={{ color: "white", fontSize: "10pt" }}>  وضعیت امنیت</span>
                                </div>
                            </NavLink>
                            <NavLink onClick={() => closeMenu()} to="/logout" exact activeClassName="header-options-item-active" title="خروج">
                                <div className="header-options-profile" title="پروفایل">
                                    <div className="header-options-icon header-options-icon-setting">
                                        <img alt="setting" src={logout} />
                                    </div>
                                    <span style={{ color: "white", fontSize: "10pt" }}>  خروج از حساب</span>
                                </div>
                            </NavLink>
                        </Menu>
                    </div>
                </div>
            </header>
        </>
    );
};

export default withRouter(Navigation);
