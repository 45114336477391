import React, { useContext, useEffect,useRef } from 'react';
import { BoxHeader, DefaultBox } from '../common/DefaultBox';
import BbitTitleIcon from '../../assets/sidebar/Iconly-Bold-Swap.svg';
import useRequest from '../../hooks/useRequest';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../state/user/userContext';

const YourLastDeal = () => {
    const { state } = useContext(UserContext);

    const { coinType } = useParams();

    const { request } = useRequest();
    const getData = () => {
        return request.get(`/Trade/GetTrade/${coinType || 2}`);
    };
    const query = useQuery(`Trade-GetTrade-${coinType || 2}`, getData);
    const { data, refetch } = query;


    
    const timer = useRef(null);
    const delay = 2;
    useEffect(() => {
        timer.current = setInterval(() => {
            refetch();
        }, delay * 1000);

        return () => {
            clearTimeout(timer.current);
        }
    }, []);

    return (
        <DefaultBox className="BBit-default-box your-last-deal-box " >
            <BoxHeader
                className="BBit-default-box-header"
                title={
                    <div className="BBit-default-box-header-title">
                        <div>آخرین معاملات</div>
                        <div>{state.selected_symble}</div>
                    </div>
                }
                icon={BbitTitleIcon}
            />
            <div className="table-head">
                <p className="d-flex title-td">زمان</p>
                <p>مقدار</p>
                <p style={{ marginRight: '8px' }}>مبلغ کل</p>
            </div>
            <div className="your-last-deal-box-details" style={{maxHeight: "300px"}}>
                {data?.data?.map((i) => (
                    <div key={i.id} className={`table-body`}>
                        <p className="d-flex title-td">{i.time}</p>
                        <p>{i.amount}</p>
                        <p style={!i.isBuy ? { color: '#F24E4E' } : { color: '#25BEA9' }}>{i.price}</p>
                    </div>
                ))}
            </div>
        </DefaultBox>
    );
};

export default YourLastDeal;
