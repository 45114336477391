import NavigationAuth from '../../component/share/NavigationAuth';
import { Helmet } from 'react-helmet';

const AuthLayout = ({ children }) => {
    return (
        <>
            <Helmet>
                <title>بی بیت</title>
            </Helmet>
            <NavigationAuth />
            <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4 p-15">{children}</div>
                <div className="col-md-4"></div>
            </div>
            <div className="footer-copy-right"><p>تمامی حقوق برای سایت بی بیت محفوظ می باشد</p></div>
        </>
    );
};
export default AuthLayout;
