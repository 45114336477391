const initialState = {
  user: {},
  token: "",
  level: {},
  selected_symble: "",
  selected_title: "",
  selectedSaller:0,
  selectedBuyer:0,
  flag: true,
};

export default initialState;
