import React, { useState, useContext, useEffect, useRef } from 'react';
import { DefaultBox } from '../common/DefaultBox';
import BbitTitleIcon from '../../assets/sidebar/Iconly-Bold-Chart.svg';
import { useHistory, useParams } from 'react-router-dom';
import useRequest from '../../hooks/useRequest';
import { useQuery } from 'react-query';
import { UserContext } from '../../state/user/userContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tab from '../common/Tab/Tab';

const BbitFav = () => {
    const { state } = useContext(UserContext);

    const history = useHistory();
    const { coinType } = useParams();
    const { request } = useRequest();
    const getData = () => {
        return request.get('/User/ListCoinSelected');
    };
    const query = useQuery('User-ListCoinSelected', getData);
    const { data, refetch } = query;


    const timer = useRef(null);
    const delay = 2;
    useEffect(() => {
        timer.current = setInterval(() => {
            refetch();
        }, delay * 1000);

        return () => {
            clearTimeout(timer.current);
        }
    }, []);


    const [value, setValue] = useState('');

    return (
        <>
            <div className="table-head1">
                <div className="box-header-img">
                    <img src={BbitTitleIcon} alt="بازار های بی بیت" />
                </div>
                <input className="searchBar"
                    onChange={e => setValue(e.target.value)}
                    placeholder="بازار های بی بیت"
                    type="text"
                    value={value}
                />
            </div>
            <div className="table-head">
                <p className="d-flex title-td">نام ارز</p>
                <p>قیمت</p>
                <p>نوسان</p>
            </div>
            <div className="store-box-details">
                {
                    data?.data?.filter((coin => {
                        if (!value) return true;
                        if (coin.title.includes(value.toUpperCase()) || coin.symbol.includes(value.toUpperCase())) {
                            return true;
                        }
                        return false;
                    })).map(({ id, image, buy, open, change, price, sell, symbol, title, toman }) => (
                        <div
                            key={id}
                            onClick={() => {
                                toast("ورود به بازار " + title + "( " + symbol + " )", {
                                    position: "top-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                //state.selected_symble = symbol;
                                //state.selected_title = title;

                                //if (state.selected_symble === symbol)
                                state.selected_id = id;
                                history.push(`/trade/${id}`);
                            }}
                            title={`ورود به بازار ${title} `}
                            className={`table-body  ${change < 0 ? 'red-gradient-bg' : 'green-gradient-bg'} coinname`}
                            style={Number(coinType) === Number(id) ? { backgroundColor: '#f5ac00' } : {}}

                        >
                            <div className="d-flex" style={{ textAlign: "center" }}>
                                <img src={image} alt="" />

                            </div>
                            <div className="d-flex title-td" style={{ display: "block", textAlign: "right" }} >
                                <p >{symbol}</p>
                                <p style={{ fontSize: "7pt", color: "#ccc" }}> {title}</p>
                            </div>

                            <div className="d-flex title-td" style={{ display: "block" }} >
                                <p className="PMprice" style={{ textAlign: "center" }} >{price} $</p>
                                <p style={{ fontSize: "7pt", textAlign: "center", color: "#ccc" }}> {toman} تومان</p>
                            </div>

                            <p className="PMprice" style={
                                open < 0
                                    ?
                                    { width: "max-content", direction: "ltr", textAlign: "center", padding: "1px 5px", backgroundColor: "#fa4155", borderRadius: "5px", color: "white", overflow: "hidden" }
                                    :
                                    { width: "max-content", direction: "ltr", textAlign: "center", padding: "1px 5px", backgroundColor: "rgb(34 157 135)", borderRadius: "5px", color: "white", overflow: "hidden" }}> {open} %</p>
                        </div>
                    ))}
            </div>
        </>
    )
}

const BbitBazar = () => {
    const { state } = useContext(UserContext);

    const history = useHistory();
    const { coinType } = useParams();
    const { request } = useRequest();
    const getData = () => {
        return request.get('/Trade/Coins');
    };
    const query = useQuery('Trade-Coins', getData);
    const { data, refetch } = query;


    const timer = useRef(null);
    const delay = 2;
    useEffect(() => {
        timer.current = setInterval(() => {
            refetch();
        }, delay * 1000);

        return () => {
            clearTimeout(timer.current);
        }
    }, []);


    const [value, setValue] = useState('');

    return (
        <>
            <div className="table-head1">
                <div className="box-header-img">
                    <img src={BbitTitleIcon} alt="بازار های بی بیت" />
                </div>
                <input className="searchBar"
                    onChange={e => setValue(e.target.value)}
                    placeholder="بازار های بی بیت"
                    type="text"
                    value={value}
                />
            </div>
            <div className="table-head">
                <p className="d-flex title-td" >عنوان ارز</p>
                <p>قیمت</p>
                <p>نوسان</p>
            </div>
            <div className="store-box-details">
                {
                    data?.data?.filter((coin => {
                        if (!value) return true;
                        if (coin.title.includes(value.toUpperCase()) || coin.symbol.includes(value.toUpperCase())) {
                            return true;
                        }
                        return false;
                    })).map(({ id, image, buy, open, change, price, sell, symbol, title, toman }) => (
                        <div
                            key={id}
                            onClick={() => {
                                toast("ورود به بازار " + title + "( " + symbol + " )", {
                                    position: "top-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                //state.selected_symble = symbol;
                                //state.selected_title = title;

                                //if (state.selected_symble === symbol)
                                state.selected_id = id;
                                history.push(`/trade/${id}`);
                            }}
                            title={`ورود به بازار ${title} `}
                            className={`table-body  ${change < 0 ? 'red-gradient-bg' : 'green-gradient-bg'} coinname`}
                            style={Number(coinType) === Number(id) ? { backgroundColor: '#f5ac00' } : {}}

                        >
                            <div className="d-flex" style={{ textAlign: "center" }}>
                                <img src={image} alt="" />

                            </div>
                            <div className="d-flex title-td" style={{ display: "block", textAlign: "right" }} >
                                <p >{symbol}</p>
                                <p style={{ fontSize: "7pt", color: "#ccc" }}> {title}</p>
                            </div>

                            <div className="d-flex title-td" style={{ display: "block" }} >
                                <p className="PMprice" style={{ textAlign: "center" }} >{price} $</p>
                                <p style={{ fontSize: "7pt", textAlign: "center", color: "#ccc" }}> {toman} تومان</p>
                            </div>

                            <p className="PMprice" style={
                                open < 0
                                    ?
                                    { width: "max-content", direction: "ltr", textAlign: "center", padding: "1px 5px", backgroundColor: "#fa4155", borderRadius: "5px", color: "white", overflow: "hidden" }
                                    :
                                    { width: "max-content", direction: "ltr", textAlign: "center", padding: "1px 5px", backgroundColor: "rgb(34 157 135)", borderRadius: "5px", color: "white", overflow: "hidden" }}> {open} %</p>
                        </div>
                    ))}
            </div>
        </>
    )
}
const BbitStore = () => {
    const tabBazar = [

        {
            key: 'fav',
            title: `  مورد علاقه `,
            content: (
                <BbitFav />
            ),
            activeColor: 'rgb(195, 240, 34)',
        },
        {
            key: 'main',
            title: `  بازار بی بیت `,
            content: (
                <BbitBazar />
            ),
            activeColor: 'rgb(161, 247, 198)',
        },

    ];
    return (

        <DefaultBox className="BBit-default-box store-box">
            <Tab
                data={tabBazar}
                onSelectedItem={() => { }}
                defaultSelectedTab={tabBazar[1]}
                style={{ backgroundColor: '#393939' }}
            />

        </DefaultBox >
    );
};

export default BbitStore;
