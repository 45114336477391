import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Tab.scss';

const Tab = (props) => {
    const { className, data = [], onSelectedItem, defaultSelectedTab, style } = props;
    const [selectedTab, setSelectedTab] = useState(
        defaultSelectedTab ? data.findIndex((i) => i.key === defaultSelectedTab.key) : 0,
    );
    useEffect(() => {
        // setSelectedTab(defaultSelectedTab ? data.findIndex((i) => i.key === defaultSelectedTab.key) : 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultSelectedTab]);
    const handleClick = (item, index) => {
        if (index !== selectedTab) {
            setSelectedTab(index);
            onSelectedItem(item);
        }
    };
    return (
        <>
            <ul style={style} className={`tab  ${className ?? ''}`}    >
                {data?.map((item, index) => {
                    return (
                        <li
                            key={item.key || index}
                            onClick={() => handleClick(item, index)}
                            style={{
                                borderRadius: "9px 9px 0px 0px",
                                borderBottom: '4px  solid ' + (index === selectedTab ? item?.activeColor : '#d1d1d165'),
                            }}
                            className={['tab-item', className, index === selectedTab ? 'tab-item-active' : ''].join(
                                ' ',
                            )}
                        >
                            {item.title}
                        </li>
                    );
                })}
            </ul>
            {data[selectedTab]?.content}
        </>
    );
};
Tab.propTypes = {
    data: PropTypes.arrayOf(Object),
    className: PropTypes.string,
    onSelectedItem: PropTypes.func,
    defaultSelectedTab: PropTypes.object,
};
export default Tab;
