import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './css/main.scss'
import { Helmet } from 'react-helmet';


ReactDOM.render(
  
  <React.StrictMode>
    <Helmet>
      <title>  بی بیت</title>
      <meta name="description" content=" خرید و فروش امن ارزهای دیجیتال با سرعت بالا " />
      <meta name="theme-color" content="#008f68" />
      <meta property="og:title" content="بی بیت" />
    </Helmet>

    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
