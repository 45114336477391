const ACTION_TYPES = {
    SET_Token: 'setToken',
    SET_USER_DATA: 'setUserData',
    SET_LEVEL: 'setLevel',
    LOGOUT: 'setLogOut',
    SET_Symble: 'setSymble',
    SET_Title: 'setTitle',
};

const userReducer = (state, action) => {
    const { type, value } = action;
    switch (type) {
        case ACTION_TYPES.SET_USER_DATA:
            return {
                ...state,
                user: value,
            };
        case ACTION_TYPES.SET_Symble:
            return {
                ...state,
                selected_symble: value,
            };
        case ACTION_TYPES.SET_Flag:
            return {
                ...state,
                flag: value,
            };
        case ACTION_TYPES.SET_Title:
            return {
                ...state,
                selected_title: value,
            };
        case ACTION_TYPES.SET_Id:
            return {
                ...state,
                selected_id: value,
            };
        case ACTION_TYPES.SET_LEVEL:
            return {
                ...state,
                level: value,
            };
        case ACTION_TYPES.SET_Token:
            return {
                ...state,
                token: value,
            };
        case ACTION_TYPES.LOGOUT:
            window.localStorage.setItem('UserData', JSON.stringify({}));
            return {
                user: {},
                level: {},
                token: '',
                selected_symble: '',
                selected_title: '',
                selected_id: '',
                flag: false,
            };
        default:
            return state;
    }
};

export default userReducer;
