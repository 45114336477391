import { useContext } from 'react';
import axios from 'axios';
import { useQuery, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../state/user/userContext';
const useRequest = () => {
    const { state, dispatch } = useContext(UserContext);
    const history = useHistory();
    const request = axios.create({
        baseURL: 'https://api.bibit.ir/api/',
        timeout: 30000,
        headers: !state?.token
            ?
            {
                'content-type': 'application/json',
            }
            : {
                'content-type': 'application/json',
                Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('UserData')).token,
            },
        "access-control-allow-origin": "*",
    });
    const request2 = axios.create({
        baseURL: 'https://api.bibit.ir/api/',
        timeout: 30000,
        headers: !state?.token
            ?
            {
                'content-type': 'application/json',
            }
            : 
            {
                'Content-type': 'multipart/form-data',
                Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('UserData')).token,
            },
        "access-control-allow-origin": "*",
    });
    request.interceptors.response.use(
        (response) => {
            return response.data;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                dispatch({
                    type: 'setLogOut',
                });
                history.push('/');
                return error;
            }
            return Promise.reject(error.response);
        },
    );

    const getData = (...arg) => {
        return request.get(...arg);
    };

    const get = useQuery('', getData);

    const postData = (...arg) => {
        return request.post(...arg);
    };

    const post = useMutation((formData) => postData('/', formData));
    const all = axios.all;
    const spread = axios.spread;

    return { request2, request, get, post, all, spread };
};

export default useRequest;
