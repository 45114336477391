import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Helmet } from 'react-helmet';

//contexts
import { UserProvider } from './state/user/userContext';
import { ToastContainer, toast } from 'react-toastify';

//Router
import AppRoutes from './router';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

function App() {
    return (
        <BrowserRouter>
            <Helmet>
                <title>  بی بیت</title>
                <meta name="description" content=" خرید و فروش امن ارزهای دیجیتال با سرعت بالا " />
                <meta name="theme-color" content="#008f68" />
                <meta property="og:title" content="بی بیت" />
            </Helmet>

            <UserProvider>
                <QueryClientProvider client={queryClient}>
                    <AppRoutes />
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </UserProvider>
            <ToastContainer />
        </BrowserRouter>
    );
}

export default App;
