import Navigation from '../../component/share/Navigation';
import NavigationTab from '../../component/share/NavigationTab';


import YourLastDeal from '../../component/SideBar/YourLastDeal';
import BbitStore from '../../component/SideBar/BbitStore';
import YourStockBox from '../../component/SideBar/YourStockBox';


const DashboardLayout = ({ children }) => {
    return (
        <div className="app-dashboard-container">
            <Navigation />
            <div className="row app-dashboard-container-content ">
                <div className="col-md-3">

                </div>
                <div className="hidden-md-down col-md-3  p-5 trade-container-content " style={{ zIndex: 1, position: "fixed", marginTop: 20, maxHeight: '100vh'}}>
                    <div className="col-md-12 pb-15">
                        <BbitStore />
                    </div>
                    <div className="col-md-12 pb-15">
                        <YourLastDeal />
                    </div>
                    <div className="col-md-12 pb-15">
                        <YourStockBox />
                    </div>
                </div>

                <div className="col-md-9 p-5">{children}</div>
            </div>
            <NavigationTab />
        </div>
    );
};
export default DashboardLayout;
