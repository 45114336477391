import { NavLink, withRouter } from 'react-router-dom';

const NavigationTab = () => {
    return (
        <ul className="nav-bottom">
            <li>
                <NavLink to="/store" exact activeClassName="nav-bottom-item-active">
                    <span className="iconly-boldChart"></span>
                    <p> بازار ها</p>
                </NavLink>
            </li>
            <li>
                <NavLink to="/trade/2" activeClassName="nav-bottom-item-active">
                    <span className="iconly-boldActivity"></span>
                    <p>خرید / فروش</p>
                </NavLink>
            </li>
            <li>
                <NavLink to="/dashboard" exact activeClassName="nav-bottom-item-active">
                    <span className="iconly-boldHome"></span>
                    <p>پیشخوان</p>
                </NavLink>
            </li>
            <li>
                <NavLink to="/your-stock" exact activeClassName="nav-bottom-item-active">
                    <span className="iconly-boldWork"></span>
                    <p>موجودی</p>
                </NavLink>
            </li>
            <li>
                <NavLink to="/wallet" exact activeClassName="nav-bottom-item-active">
                    <span className="iconly-boldWallet"></span>
                    <p>کیف پول</p>
                </NavLink>
            </li>
        </ul>
    );
};

export default withRouter(NavigationTab);
