import React, { useRef, useEffect } from 'react';
import { BoxHeader, DefaultBox } from '../common/DefaultBox';
import wallet from '../../assets/sidebar/Wallet.svg';
import useRequest from '../../hooks/useRequest';
import { useQuery } from 'react-query';
import { isNonEmptyArray } from '@apollo/client/utilities';
import { useHistory } from 'react-router-dom';

const YourStockBox = () => {


    const history = useHistory();

    const { request } = useRequest();
    const getData = () => {
        return request.get('/User/UserBalance');
    };
    const query = useQuery('User-UserBalance', getData);
    const { data, refetch } = query;
    let sum = 0;
    function commafy(num) {
        var str = num.toString().split('.');
        if (str[0].length >= 5) {
            str[0] = str[0].toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        if (str[1] && str[1].length >= 5) {
            str[1] = str[1].toString().replace(/(\d{3})/g, '$1 ');
        }
        return str.join('.');
    }
    if (isNonEmptyArray(data?.data)) {
        data?.data?.forEach(element => {
            sum = sum + parseFloat(element.price);
        });
    }


    const timer = useRef(null);
    const delay = 10;
    useEffect(() => {
        timer.current = setInterval(() => {
            refetch();
        }, delay * 1000);

        return () => {
            clearTimeout(timer.current);
        }
    }, []);



    function goto(id) {
        history.push(`/trade/${id}`)
    }
    return (
        <DefaultBox className="BBit-default-box your-stock-box  ">
            <BoxHeader className="BBit-default-box-header " title={"  موجودی های شما ( " + commafy(sum) + " ) تومان"} icon={wallet} />

            <div className="your-stock-box-table-head" style={{ gridTemplateColumns: "70px  67px 50px" }}>
                <p className="d-flex title-td">نوع ارز</p>
                <p>ارزش تقریبی</p>
                <p>مقدار</p>
            </div>
            <div className="your-stock-box-details" style={{ overflow: "scroll", height: "auto" }} >
                {data?.data?.map(({ coinId, persianTitle, amount, symbol, toman }, index) => (
                    <div key={coinId} className="your-stock-box-table-body" style={{ gridTemplateColumns: "70px  67px 50px" }}>
                        <div className="d-flex title-td">
                            <p>{symbol}</p>
                        </div>
                        <p>{toman}</p>
                        <p>{amount}</p>
                    </div>
                ))}
            </div>
        </DefaultBox>

    );
};

export default YourStockBox;
