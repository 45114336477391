import React, { createContext } from 'react';

import reducer from './reducer';
import initialState from './initialState';

//hooks
import { usePersistState } from '../../hooks/usePersistState';

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const usePersistedReducer = usePersistState('UserData');
    const [state, dispatch] = usePersistedReducer(reducer, initialState);  

    return (
        <UserContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export { UserProvider, UserContext };
