import React from 'react';
import Logo from '../../assets/logo.svg';
import Profil from '../../assets/menu/Profile.svg';
import { NavLink, useHistory, withRouter } from 'react-router-dom';

const NavigationAuth = () => {
    const history = useHistory();
    const currentURL = history.location.pathname;

    return (
        <>
            <header className="header-auth">
                <div className="header-auth-part m-auto">
                    <NavLink to={'/dashboard'} exact>
                        <div className="header-auth-logo">
                            <img src={Logo} alt="logo" />
                            <h3>بی بیت</h3>
                        </div>
                    </NavLink>

                    <nav className="header-auth-nav"></nav>
                </div>

                <div className="header-auth-part">
                    <div className="header-auth-options">
                        <NavLink to="/login" exact activeClassName="header-auth-options-item-active">
                            <div className="header-auth-options-profile">
                                <div className="header-auth-options-icon header-auth-options-icon-avatar">
                                    <img alt="user" src={Profil} />
                                </div>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </header>
        </>
    );
};

export default withRouter(NavigationAuth);
