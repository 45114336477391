import React from 'react';
import BbitStore from '../SideBar/BbitStore';
export const DefaultBox = ({ className, children }) => {
    return <div className={`default-box ${className}`} >{children}</div>;
};

export const BoxHeader = ({ className, title, icon }) => {
    return (
        <div className={`  ${className}`}>
            <div className="box-header">
                <div className="box-header-img">
                    <img src={icon} alt={title} />
                </div>
                <h6 className="box-header-title">{title}</h6>

            </div>
        </div>
    );
};
export const BoxHeaderKif = ({ className, title, icon, p }) => {
    return (
        <div className={`  ${className}`}>
            <div className="box-header">
                <div className="box-header-img">
                    <img src={icon} alt={title} />
                </div>
                <h6 className="box-header-title">{title}</h6>
                <p className="kif" style={{minWidth:"200px"}}>{p}</p>
            </div>
        </div>
    );
};

const changeState = e => {
    if (e.target.value)
        BbitStore.keysearch = e.target.value;
    else
        BbitStore.keysearch = ' ';
};
export const BoxHeaderSearch = ({ className, title, icon }) => {

    return (
        <div className={`  ${className}`}>
            <div className="box-header">
                <div className="box-header-img">
                    <img src={icon} alt={title} />
                </div>
                <input className="searchBar"
                    onChange={changeState}
                    placeholder={title}
                    type="text"
                    name="search_crypto"
                />
            </div>

        </div>
    );
};
