import { Helmet } from 'react-helmet';
const PublicLayout = ({ children }) => {
    return (
        <>
            <Helmet>
                <title>بی بیت</title>
            </Helmet>
            <div className="row">
                <div className="col-md-4">صفحات  عمومی</div>
                <div className="col-md-4 p-15">{children}</div>
                <div className="col-md-4"></div>
            </div>
        </>
    );
};
export default PublicLayout;
