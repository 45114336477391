import React, { lazy, Suspense, useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

//contexts
import { UserContext } from '../state/user/userContext';

//Pages
import PublicLayout from '../component/common/PublicLayout';
import AuthLayout from '../component/common/AuthLayout';
import DashboardLayout from '../component/common/DashboardLayout';

const Login = lazy(() => import('../layout/Auth/Login'));
const TwoFactor = lazy(() => import('../layout/Auth/TwoFactor'));
const Register = lazy(() => import('../layout/Auth/Register'));
const SetPassword = lazy(() => import('../layout/Auth/SetPassword'));
const ForgetPassword = lazy(() => import('../layout/Auth/ForgetPassword'));
const Logout = lazy(() => import('../layout/Auth/Logout'));

const YourLastDeal = lazy(() => import('../component/SideBar/YourLastDeal'));
const Echarge = lazy(() => import('../Services/Echarge'));
const BbitStore = lazy(() => import('../component/SideBar/BbitStore'));
const YourStockBox = lazy(() => import('../component/SideBar/YourStockBox'));
const Trade = lazy(() => import('../layout/Trade'));
const Wallet = lazy(() => import('../layout/Wallet'));
const WalleteToman = lazy(() => import('../component/wallet/WalleteToman'));
const ConvertSmallBalance = lazy(() => import('../component/wallet/convertSmallBalance'));

const WalleteToman1 = lazy(() => import('../component/wallet/WalleteToman1'));
const WalletBardashToman = lazy(() => import('../component/wallet/walletBardashToman'));
const WalletBardashToman1 = lazy(() => import('../component/wallet/walletBardashToman1'));
const WalleteBrdashtBitCoin = lazy(() => import('../component/wallet/WalleteBrdashtBitCoin'));
const WalletBitCoin = lazy(() => import('../component/wallet/WalletBitCoin'));
const WalletTeter = lazy(() => import('../component/wallet/WalletTeter'));
const WalletNotification = lazy(() => import('../component/wallet/WalletNotification'));
const Order = lazy(() => import('../component/order/Order'));
const Setting = lazy(() => import('../layout/Setting'));
const InviteFriends = lazy(() => import('../layout/InviteFriends'));

const Dashboard = lazy(() => import('../layout/Dashboard'));
const Profile = lazy(() => import('../layout/Profile'));
const Ticket = lazy(() => import('../Tickets/Ticket'));

const LoadingFallback = ({ type }) =>
    type === 'Dashboard' ? <DashboardLayout></DashboardLayout> : <AuthLayout></AuthLayout>;

export const PublicRoutes = ({ children, ...rest }) => {
    return <Route {...rest} render={() => <PublicLayout>{children}</PublicLayout>}></Route>;
};

export const AuthenticatedRoute = ({ children, path, ...rest }) => {
    //Use Context
    const { state } = useContext(UserContext);
    return (
        <Route
            {...rest}
            render={() => (state?.token ? <DashboardLayout>{children}</DashboardLayout> : <Redirect to="/login" />)}
        ></Route>
    );
};

export const AuthRoutes = ({ children, ...rest }) => {
    const { state } = useContext(UserContext);
    return (
        <Route
            {...rest}
            render={() => (
                !state?.token
                    ?
                    <AuthLayout>{children}</AuthLayout>
                    :
                    <Redirect to="/dashboard" />)}
        ></Route>
    );
};

const AppRoutes = () => {
    return (
        <div className="app">
            <Suspense fallback={<LoadingFallback />}>
                <Switch>
                    <AuthRoutes path="/login">
                        <Login />
                    </AuthRoutes>
                    <AuthRoutes path="/twofactor/:email">
                        <TwoFactor />
                    </AuthRoutes>

                    <AuthRoutes path="/register/:code?">
                        <Register />
                    </AuthRoutes>
                    <AuthRoutes path="/set-password/:userId">
                        <SetPassword />
                    </AuthRoutes>
                    <AuthRoutes path="/forget-password">
                        <ForgetPassword />
                    </AuthRoutes>
                </Switch>
            </Suspense>
            <Suspense fallback={<LoadingFallback type="Dashboard" />}>
                <Switch>
                    <AuthenticatedRoute exact path="/">
                        <Dashboard />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/logout">
                        <Logout />
                    </AuthenticatedRoute>

                    <PublicRoutes path="/landing">landing</PublicRoutes>

                    <AuthenticatedRoute path="/dashboard">
                        <Dashboard />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/Echarge">
                        <Echarge />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/trade/:coinType">
                        <Trade />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/setting">
                        <Setting />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/profile">
                        <Profile />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/ticket">
                        <Ticket />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/invite-friend">
                        <InviteFriends />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/wallet/deposit-Tether/:coinType2">
                        <WalleteToman />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/convertSmallBalance">
                        <ConvertSmallBalance />
                    </AuthenticatedRoute>

                    <AuthenticatedRoute path="/wallet/deposit1-toman/:coinType">
                        <WalleteToman1 />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/wallet/withdrawal-toman/:coinType">
                        <WalletBardashToman />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/wallet/withdrawal-toman1/">
                        <WalletBardashToman1 />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/wallet/withdrawal-coin/:coinType2">
                        <WalleteBrdashtBitCoin />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/wallet/bitcoin">
                        <WalletBitCoin />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/wallet/tether">
                        <WalletTeter />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/wallet">
                        <Wallet />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/notification">
                        <WalletNotification />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/order">
                        <Order />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/store">
                        <BbitStore />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/your-stock">
                        <YourStockBox />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/latest-deals">
                        <YourLastDeal />
                    </AuthenticatedRoute>

                    {/*<AuthenticatedRoute path="*">
                        <Dashboard />
    </AuthenticatedRoute>*/}
                </Switch>
            </Suspense>
        </div>
    );
};

export default AppRoutes;
